import React, {Component} from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    InputGroup,
    ControlLabel,
    Glyphicon,
    Nav,
    NavItem,
    Tab
} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import axios from "axios/index";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {Redirect} from "react-router";
import Button from "../../components/CustomButton/CustomButton";
import ModalSave from "../../components/CustomModals/ModalSave";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {DualListBoxContentProviders} from "../../components/DualListBox/DualListBoxContentProviders";
import {
    DualListBoxContentProvidersSvodPackages
} from "../../components/DualListBox/DualListBoxContentProvidersSvodPackages";
import {
    DualListBoxContentProvidersLivePackages
} from "../../components/DualListBox/DualListBoxContentProvidersLivePackages";
import {
    DualListBoxContentProvidersEventPackages
} from "../../components/DualListBox/DualListBoxContentProvidersEventPackages";
import {
    DualListBoxContentProvidersSportPackages
} from "../../components/DualListBox/DualListBoxContentProvidersSportPackages";
import {DualListBoxCategoriesTv} from "../../components/DualListBox/DualListBoxCategoriesTv";
import {DualListBoxCategoriesVod} from "../../components/DualListBox/DualListBoxCategoriesVod";
import {DualListBoxCategoriesSports} from "../../components/DualListBox/DualListBoxCategoriesSports";
import {DualListBoxCategoriesRadio} from "../../components/DualListBox/DualListBoxCategoriesRadio";
import {DualListBoxCategoriesEvents} from "../../components/DualListBox/DualListBoxCategoriesEvents";
import {DeviceApkManagementComponent} from "../../components/CustomComponents/DeviceApkManagementComponent";
import {DeviceMenuComponent} from "../../components/CustomComponents/DeviceMenuComponent";
import {DeviceOrderingComponent} from "../../components/CustomComponents/DeviceOrderingComponent";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import ImageUpload from "../../components/ImageUpload/ImageUpload";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
`;


class ApplicationManagementEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            redirect: false,
            back_button_text: "Cancel",
            showSaveModal: false,
            newPackage: true,

            applicationRedirect: false,
            applicationObject: [],

            application_id: 0,
            application_name: null,
            application_description: null,
            application_sso_url: null,
            application_event_packages: null,
            application_sport_packages: null,
            application_live_packages: null,
            application_svod_packages: null,
            application_providers: null,
            application_domain: null,
            custom_data: null,
            application_tenant_id: null,
            application_tenant_name: null,

            assignedLivePackages: [],
            assignedSvodPackages: [],
            assignedEventPackages: [],
            assignedSportPackages: [],
            assignedContentProviders: [],

            assigned_sport_categories: [],
            assigned_event_categories: [],
            assigned_vod_categories: [],
            assigned_radio_categories: [],
            assigned_tv_categories: [],

            current_user: null,

            _notificationSystem: null,
            selectedTab: "customer_tab_general",

            application_image: null,
            application_image_temp: false,
            application_image_folder_temp: "",

            application_user_profile_limit: null,
            application_user_stb_device_limit: null,
            application_user_mobile_device_limit: null,
            application_user_concurrent_stream_limit: null,
            application_user_dvr_usage_limit: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEventPackageChange = this.handleEventPackageChange.bind(this);
        this.handleSportEventPackageChange = this.handleSportEventPackageChange.bind(this);
        this.handleLivePackageChange = this.handleLivePackageChange.bind(this);
        this.handleSvodPackageChange = this.handleSvodPackageChange.bind(this);
        this.handleContentProvidersChange = this.handleContentProvidersChange.bind(this);
        this.handleApplicationImageSelect = this.handleApplicationImageSelect.bind(this);

        this.handleSportCategoriesChange = this.handleSportCategoriesChange.bind(this);
        this.handleEventCategoriesChange = this.handleEventCategoriesChange.bind(this);
        this.handleVodCategoriesChange = this.handleVodCategoriesChange.bind(this);
        this.handleRadioCategoriesChange = this.handleRadioCategoriesChange.bind(this);
        this.handleTvCategoriesChange = this.handleTvCategoriesChange.bind(this);
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Event Package has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Event Package</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    handleApplicationImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "application_image") {
            if (filename !== null) {
                this.setState({
                    application_image_temp: true,
                    application_image: filename,
                    application_image_folder_temp: folder
                });
            } else {
                this.setState({
                    application_image_temp: false,
                    application_image: null,
                    application_image_folder_temp: ""
                });
            }

        }

    }

    submitApplicationImage(filename_poster) {

        if (this.state.application_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.application_image_folder_temp);
            bodyFormData.set('location', "applications");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({application_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleSportCategoriesChange(selectedFromChild) {

        let selectedSportCategories = []

        if (selectedFromChild !== undefined !== null) {
            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedSportCategories.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assigned_sport_categories: selectedSportCategories})
    }

    handleEventCategoriesChange(selectedFromChild) {

        let selectedEventCategories = []

        if (selectedFromChild !== undefined !== null) {
            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedEventCategories.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assigned_event_categories: selectedEventCategories})
    }

    handleVodCategoriesChange(selectedFromChild) {

        let selectedVodCategories = []

        if (selectedFromChild !== undefined !== null) {
            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedVodCategories.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assigned_vod_categories: selectedVodCategories})
    }

    handleRadioCategoriesChange(selectedFromChild) {

        let selectedRadioCategories = []

        if (selectedFromChild !== undefined !== null) {
            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedRadioCategories.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assigned_radio_categories: selectedRadioCategories})
    }

    handleTvCategoriesChange(selectedFromChild) {

        let selectedTvCategories = []

        if (selectedFromChild !== undefined !== null) {
            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedTvCategories.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assigned_tv_categories: selectedTvCategories})
    }

    handleEventPackageChange(selectedFromChild) {

        let selectedEvents = []

        if (selectedFromChild !== undefined !== null) {

            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedEvents.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assignedEventPackages: selectedEvents})
    }

    handleSportEventPackageChange(selectedFromChild) {

        let selectedSportEvents = []

        if (selectedFromChild !== undefined !== null) {

            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedSportEvents.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assignedSportPackages: selectedSportEvents})
    }

    handleLivePackageChange(selectedFromChild) {

        let selectedLivePackages = []

        if (selectedFromChild !== undefined !== null) {
            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedLivePackages.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assignedLivePackages: selectedLivePackages})
    }

    handleSvodPackageChange(selectedFromChild) {

        let selectedSvodPackages = []

        if (selectedFromChild !== undefined !== null) {

            for (let i = 0; i < selectedFromChild.length; i++) {
                if (selectedFromChild[i].toString !== '') {
                    selectedSvodPackages.push(selectedFromChild[i].toString())
                }
            }
        }

        this.setState({assignedSvodPackages: selectedSvodPackages})
    }

    handleContentProvidersChange(selectedFromChild) {

        let selectedContentProviders = []

        if (selectedFromChild !== undefined !== null) {

            for (let i = 0; i < selectedFromChild.length; i++) {
                selectedContentProviders.push(selectedFromChild[i].toString())
            }
        }

        this.setState({
            assignedContentProviders: selectedContentProviders,
            assignedLivePackages: [],
            assignedSvodPackages: [],
            assignedEventPackages: [],
            assignedSportPackages: []
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    convertStringToArray(stringArray) {

        console.log(stringArray);
        let resultArray = [];

        if (stringArray !== undefined) {

            if (stringArray !== null) {
                resultArray = stringArray.toString().split(",")
            }
        }

        return resultArray
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params

        if (handle === 'new') {
            let user = JSON.parse(localStorage.getItem('user'));
            this.setState({loading: false, newPackage: false, current_user: user})

        } else {

            this.setState({loading: true})
            const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + handle;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const applicationObject = res.data[0];
                    const loading = false;

                    let selectedContentLive = []
                    let selectedContentSvod = []
                    let selectedContentEvents = []
                    let selectedContentSport = []
                    let selectedContentProviders = []

                    let selectedTvCategories = []
                    let selectedRadioCategories = []
                    let selectedEventCategories = []
                    let selectedSportCategories = []
                    let selectedVodCategories = []

                    selectedContentLive = this.convertStringToArray(applicationObject.application_live_packages);
                    selectedContentSvod = this.convertStringToArray(applicationObject.application_svod_packages);
                    selectedContentEvents = this.convertStringToArray(applicationObject.application_event_packages);
                    selectedContentSport = this.convertStringToArray(applicationObject.application_sport_packages);
                    selectedContentProviders = this.convertStringToArray(applicationObject.application_providers);
                    selectedSportCategories = this.convertStringToArray(applicationObject.application_sport_categories);
                    selectedEventCategories = this.convertStringToArray(applicationObject.application_event_categories);
                    selectedVodCategories = this.convertStringToArray(applicationObject.application_vod_categories);
                    selectedRadioCategories = this.convertStringToArray(applicationObject.application_radio_categories);
                    selectedTvCategories = this.convertStringToArray(applicationObject.application_tv_categories);

                    this.setState({
                        back_button_text: "Back",
                        application_id: applicationObject.id,
                        application_name: applicationObject.application_name,

                        application_event_packages: selectedContentEvents,
                        application_sport_packages: selectedContentSport,
                        application_live_packages: selectedContentLive,
                        application_svod_packages: selectedContentSvod,
                        application_providers: selectedContentProviders,

                        application_description: applicationObject.application_description,
                        application_sso_url: applicationObject.application_sso_url,
                        custom_data: applicationObject.custom_data,

                        assignedLivePackages: selectedContentLive,
                        assignedSvodPackages: selectedContentSvod,
                        assignedEventPackages: selectedContentEvents,
                        assignedSportPackages: selectedContentSport,
                        assignedContentProviders: selectedContentProviders,

                        assigned_sport_categories: selectedSportCategories,
                        assigned_event_categories: selectedEventCategories,
                        assigned_vod_categories: selectedVodCategories,
                        assigned_radio_categories: selectedRadioCategories,
                        assigned_tv_categories: selectedTvCategories,

                        application_tenant_id: applicationObject.application_tenant_id,
                        application_tenant_name: applicationObject.application_tenant_name,
                        application_domain: applicationObject.application_domain,
                        application_image: applicationObject.application_image,

                        application_user_profile_limit: applicationObject.application_user_profile_limit,
                        application_user_stb_device_limit: applicationObject.application_user_stb_device_limit,
                        application_user_mobile_device_limit: applicationObject.application_user_mobile_device_limit,
                        application_user_concurrent_stream_limit: applicationObject.application_user_concurrent_stream_limit,
                        application_user_dvr_usage_limit: applicationObject.application_user_dvr_usage_limit,

                        loading,
                        applicationObject,
                        current_user: user,
                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
        }
    }

    updateApplication() {

        this.setState({loading: true})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + handle;


        let filename_image = ""

        if (this.state.application_image_temp) {
            let timeUTC = new Date().getTime()
            filename_image = timeUTC + "_" + this.state.application_image
            this.setState({application_image: filename_image})
            this.submitApplicationImage(filename_image);
        } else {
            filename_image = this.state.application_image
        }

        // Send a PUT request
        let postData = {
            type: "update_application",
            application_id: this.state.application_id,
            application_name: this.state.application_name,
            application_description: this.state.application_description,
            application_event_packages: this.state.assignedEventPackages.join(),
            application_sport_packages: this.state.assignedSportPackages.join(),
            application_live_packages: this.state.assignedLivePackages.join(),
            application_svod_packages: this.state.assignedSvodPackages.join(),
            application_providers: this.state.assignedContentProviders.join(),

            application_sport_categories: this.state.assigned_sport_categories.join(),
            application_event_categories: this.state.assigned_event_categories.join(),
            application_vod_categories: this.state.assigned_vod_categories.join(),
            application_radio_categories: this.state.assigned_radio_categories.join(),
            application_tv_categories: this.state.assigned_tv_categories.join(),

            application_sso_url: this.state.application_sso_url,
            custom_data: this.state.custom_data,
            application_tenant_id: this.state.application_tenant_id,
            application_tenant_name: this.state.application_tenant_name,
            application_domain: this.state.application_domain,
            application_image: filename_image,

            application_user_profile_limit: this.state.application_user_profile_limit,
            application_user_stb_device_limit: this.state.application_user_stb_device_limit,
            application_user_mobile_device_limit: this.state.application_user_mobile_device_limit,
            application_user_concurrent_stream_limit: this.state.application_user_concurrent_stream_limit,
            application_user_dvr_usage_limit: this.state.application_user_dvr_usage_limit

        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    createApplication() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement";

        let filename_image = ""

        if (this.state.application_image_temp) {
            let timeUTC = new Date().getTime()
            filename_image = timeUTC + "_" + this.state.application_image
            this.setState({application_image: filename_image})
            this.submitApplicationImage(filename_image);
        } else {
            filename_image = this.state.application_image
        }

        let postData = {
            application_name: this.state.application_name,
            application_description: this.state.application_description,
            application_event_packages: this.state.assignedEventPackages.join(),
            application_sport_packages: this.state.assignedSportPackages.join(),
            application_live_packages: this.state.assignedLivePackages.join(),
            application_svod_packages: this.state.assignedSvodPackages.join(),
            application_providers: this.state.assignedContentProviders.join(),

            application_sport_categories: this.state.assigned_sport_categories.join(),
            application_event_categories: this.state.assigned_event_categories.join(),
            application_vod_categories: this.state.assigned_vod_categories.join(),
            application_radio_categories: this.state.assigned_radio_categories.join(),
            application_tv_categories: this.state.assigned_tv_categories.join(),

            application_sso_url: this.state.application_sso_url,
            custom_data: this.state.custom_data,
            application_tenant_id: this.state.application_tenant_id,
            application_tenant_name: this.state.application_tenant_name,
            application_domain: this.state.application_domain,
            application_image: filename_image,

            application_user_profile_limit: this.state.application_user_profile_limit,
            application_user_stb_device_limit: this.state.application_user_stb_device_limit,
            application_user_mobile_device_limit: this.state.application_user_mobile_device_limit,
            application_user_concurrent_stream_limit: this.state.application_user_concurrent_stream_limit,
            application_user_dvr_usage_limit: this.state.application_user_dvr_usage_limit

        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    const applicationObject = res.data;
                    this.setState({application_id: applicationObject.id})
                    this.setApplicationRedirect();
                    this.notification("success")
                }
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }


    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({loading: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createApplication();
            } else {
                this.updateApplication();
            }
        }
    }

    cancelApplicationEdit() {
        this.setRedirect();
    }

    setApplicationRedirect = () => {
        this.setState({
            applicationRedirect: true,
            back_button_text: "Back"
        })
    }


    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Application: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.application_id === 0 ? '' : this.state.application_id}
            />
        }

    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/settings-app-management";
        let pathApplication = "/edit-application/" + this.state.application_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.applicationRedirect) {

            this.setState({
                applicationRedirect: false,
                redirect: false
            })

            return <Redirect push to={pathApplication}/>
        }

    }


    renderLiveSelection = () => {

        return (
            <Card
                content={
                    <div>
                        <h5>Live Package Selection</h5>
                        <legend/>
                        <Grid fluid>
                            <Row>
                                <DualListBoxContentProvidersLivePackages
                                    assignedLivePackages={this.state.assignedLivePackages}
                                    contentProviders={this.state.assignedContentProviders}
                                    handlerFromParent={this.handleLivePackageChange}
                                />
                            </Row>
                        </Grid>
                    </div>
                }
                ftTextCenter
                legend={
                    <div className="buttons-with-margin">
                        <Button bsStyle="info" fill wd
                                name={"saveButton"}
                                disabled={this.state.loading}
                                onClick={() => this.setState({showSaveModal: true})}
                        >
                            Save
                        </Button>
                        <Button bsStyle="default" fill wd
                                name={"cancelButton"}
                                disabled={this.state.loading}
                                onClick={this.cancelApplicationEdit.bind(this)}>
                            {this.state.back_button_text}
                        </Button>
                    </div>
                }
            />
        )

    }

    renderCategories = () => {

        return (
            <div>
                <Card
                    content={
                        <div>
                            <div>
                                <h5>Live Categories Selection</h5>
                                <legend/>
                                <Grid fluid>
                                    <Row>
                                        <DualListBoxCategoriesTv
                                            assignedTvCategories={this.state.assigned_tv_categories}
                                            handlerFromParent={this.handleTvCategoriesChange}
                                        />
                                    </Row>
                                </Grid>
                            </div>

                        </div>
                    }
                    ftTextCenter
                    legend={
                        <div className="buttons-with-margin">
                            <Button bsStyle="info" fill wd
                                    name={"saveButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({showSaveModal: true})}
                            >
                                Save
                            </Button>
                            <Button bsStyle="default" fill wd
                                    name={"cancelButton"}
                                    disabled={this.state.loading}
                                    onClick={this.cancelApplicationEdit.bind(this)}>
                                {this.state.back_button_text}
                            </Button>
                        </div>
                    }
                />
                <Card
                    content={
                        <div>
                            <div>
                                <h5>Radio Categories Selection</h5>
                                <legend/>
                                <Grid fluid>
                                    <Row>
                                        <DualListBoxCategoriesRadio
                                            assignedRadioCategories={this.state.assigned_radio_categories}
                                            handlerFromParent={this.handleRadioCategoriesChange}
                                        />
                                    </Row>
                                </Grid>
                            </div>

                        </div>
                    }
                    ftTextCenter
                    legend={
                        <div className="buttons-with-margin">
                            <Button bsStyle="info" fill wd
                                    name={"saveButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({showSaveModal: true})}
                            >
                                Save
                            </Button>
                            <Button bsStyle="default" fill wd
                                    name={"cancelButton"}
                                    disabled={this.state.loading}
                                    onClick={this.cancelApplicationEdit.bind(this)}>
                                {this.state.back_button_text}
                            </Button>
                        </div>
                    }
                />
                <Card
                    content={
                        <div>
                            <div>
                                <h5>Sport Categories Selection</h5>
                                <legend/>
                                <Grid fluid>
                                    <Row>
                                        <DualListBoxCategoriesSports
                                            assignedSportCategories={this.state.assigned_sport_categories}
                                            handlerFromParent={this.handleSportCategoriesChange}
                                        />
                                    </Row>
                                </Grid>
                            </div>

                        </div>
                    }
                    ftTextCenter
                    legend={
                        <div className="buttons-with-margin">
                            <Button bsStyle="info" fill wd
                                    name={"saveButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({showSaveModal: true})}
                            >
                                Save
                            </Button>
                            <Button bsStyle="default" fill wd
                                    name={"cancelButton"}
                                    disabled={this.state.loading}
                                    onClick={this.cancelApplicationEdit.bind(this)}>
                                {this.state.back_button_text}
                            </Button>
                        </div>
                    }
                />
                <Card
                    content={
                        <div>
                            <div>
                                <h5>Event Categories Selection</h5>
                                <legend/>
                                <Grid fluid>
                                    <Row>
                                        <DualListBoxCategoriesEvents
                                            assignedEventCategories={this.state.assigned_event_categories}
                                            handlerFromParent={this.handleEventCategoriesChange}
                                        />
                                    </Row>
                                </Grid>
                            </div>

                        </div>
                    }
                    ftTextCenter
                    legend={
                        <div className="buttons-with-margin">
                            <Button bsStyle="info" fill wd
                                    name={"saveButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({showSaveModal: true})}
                            >
                                Save
                            </Button>
                            <Button bsStyle="default" fill wd
                                    name={"cancelButton"}
                                    disabled={this.state.loading}
                                    onClick={this.cancelApplicationEdit.bind(this)}>
                                {this.state.back_button_text}
                            </Button>
                        </div>
                    }
                />
                <Card
                    content={
                        <div>
                            <div>
                                <h5>VOD Categories Selection</h5>
                                <legend/>
                                <Grid fluid>
                                    <Row>
                                        <DualListBoxCategoriesVod
                                            assignedVodCategories={this.state.assigned_vod_categories}
                                            handlerFromParent={this.handleVodCategoriesChange}
                                        />
                                    </Row>
                                </Grid>
                            </div>

                        </div>
                    }
                    ftTextCenter
                    legend={
                        <div className="buttons-with-margin">
                            <Button bsStyle="info" fill wd
                                    name={"saveButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({showSaveModal: true})}
                            >
                                Save
                            </Button>
                            <Button bsStyle="default" fill wd
                                    name={"cancelButton"}
                                    disabled={this.state.loading}
                                    onClick={this.cancelApplicationEdit.bind(this)}>
                                {this.state.back_button_text}
                            </Button>
                        </div>
                    }
                />
            </div>

        )

    }

    renderEventSelection = () => {

        return (
            <Card
                content={
                    <div>
                        <h5>Event Package Selection</h5>
                        <legend/>
                        <Grid fluid>
                            <Row>
                                <DualListBoxContentProvidersEventPackages
                                    assignedEventPackages={this.state.assignedEventPackages}
                                    contentProviders={this.state.assignedContentProviders}
                                    handlerFromParent={this.handleEventPackageChange}
                                />
                            </Row>
                        </Grid>
                    </div>
                }
                ftTextCenter
                legend={
                    <div className="buttons-with-margin">
                        <Button bsStyle="info" fill wd
                                name={"saveButton"}
                                disabled={this.state.loading}
                                onClick={() => this.setState({showSaveModal: true})}
                        >
                            Save
                        </Button>
                        <Button bsStyle="default" fill wd
                                name={"cancelButton"}
                                disabled={this.state.loading}
                                onClick={this.cancelApplicationEdit.bind(this)}>
                            {this.state.back_button_text}
                        </Button>
                    </div>
                }
            />
        )

    }

    renderSvodSelection = () => {

        return (
            <Card
                content={
                    <div>
                        <h5>SVOD Package Selection</h5>
                        <legend/>
                        <Grid fluid>
                            <Row>
                                <DualListBoxContentProvidersSvodPackages
                                    assignedSvodPackages={this.state.assignedSvodPackages}
                                    contentProviders={this.state.assignedContentProviders}
                                    handlerFromParent={this.handleSvodPackageChange}
                                />
                            </Row>
                        </Grid>
                    </div>
                }
                ftTextCenter
                legend={
                    <div className="buttons-with-margin">
                        <Button bsStyle="info" fill wd
                                name={"saveButton"}
                                disabled={this.state.loading}
                                onClick={() => this.setState({showSaveModal: true})}
                        >
                            Save
                        </Button>
                        <Button bsStyle="default" fill wd
                                name={"cancelButton"}
                                disabled={this.state.loading}
                                onClick={this.cancelApplicationEdit.bind(this)}>
                            {this.state.back_button_text}
                        </Button>
                    </div>
                }
            />
        )

    }

    renderSportPackageSelection = () => {

        return (
            <Card
                content={
                    <div>
                        <h5>Sport Package Selection</h5>
                        <legend/>
                        <Grid fluid>
                            <Row>
                                <DualListBoxContentProvidersSportPackages
                                    assignedSportPackages={this.state.assignedSportPackages}
                                    contentProviders={this.state.assignedContentProviders}
                                    handlerFromParent={this.handleSportEventPackageChange}
                                />
                            </Row>
                        </Grid>
                    </div>
                }
                ftTextCenter
                legend={
                    <div className="buttons-with-margin">
                        <Button bsStyle="info" fill wd
                                name={"saveButton"}
                                disabled={this.state.loading}
                                onClick={() => this.setState({showSaveModal: true})}
                        >
                            Save
                        </Button>
                        <Button bsStyle="default" fill wd
                                name={"cancelButton"}
                                disabled={this.state.loading}
                                onClick={this.cancelApplicationEdit.bind(this)}>
                            {this.state.back_button_text}
                        </Button>
                    </div>
                }
            />
        )

    }

    renderContentProviderSelection = () => {

        return (
            <div>
                <h5>Content Providers Selection</h5>
                <legend/>
                <Grid fluid>
                    <Row>
                        <DualListBoxContentProviders
                            assignedContentProviders={this.state.assignedContentProviders}
                            handlerFromParent={this.handleContentProvidersChange}
                        />
                    </Row>
                </Grid>
            </div>
        )

    }

    renderDeviceLimits = () => {

        return (
            <div>
                <h5>Default Account Limits</h5>
                <legend/>
                <Grid fluid>
                    <Row>
                        <Col sm={12}>

                            <FormGroup>
                                <ControlLabel>Profile Limit <code> -1 Unlimited</code></ControlLabel>
                                <FormControl
                                    placeholder="Enter profile limit"
                                    type="number"
                                    name="application_user_profile_limit"
                                    onChange={this.handleInputChange}
                                    value={this.state.application_user_profile_limit || ""}
                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Set Top Box Device Limit <code> -1 Unlimited</code></ControlLabel>
                                <FormControl
                                    placeholder="Enter STB Device limit"
                                    type="number"
                                    name="application_user_stb_device_limit"
                                    onChange={this.handleInputChange}
                                    value={this.state.application_user_stb_device_limit || ""}
                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Mobile Device Device Limit <code>-1 Unlimited</code>
                                </ControlLabel>
                                <FormControl
                                    placeholder="Enter Mobile Device limit"
                                    type="number"
                                    name="application_user_mobile_device_limit"
                                    onChange={this.handleInputChange}
                                    value={this.state.application_user_mobile_device_limit || ""}
                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Concurrent Stream Limit<code> -1 Unlimited</code> </ControlLabel>
                                <FormControl
                                    placeholder="Enter Stream limit"
                                    type="number"
                                    name="application_user_concurrent_stream_limit"
                                    onChange={this.handleInputChange}
                                    value={this.state.application_user_concurrent_stream_limit || ""}
                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>DVR Limit <code>(hours)</code> </ControlLabel>
                                <FormControl
                                    placeholder="Enter DVR limit"
                                    type="number"
                                    name="application_user_dvr_usage_limit"
                                    onChange={this.handleInputChange}
                                    value={this.state.application_user_dvr_usage_limit || ""}
                                />
                            </FormGroup>


                        </Col>
                    </Row>
                </Grid>
            </div>
        )

    }

    renderMainTab = () => {
        return (

            <Card
                content={

                    <Grid fluid>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <h5>Application Information</h5>
                                <legend/>

                                <FormGroup>
                                    <ControlLabel>Name</ControlLabel>
                                    <FormControl placeholder="Enter name of application"
                                                 type="text"
                                                 name={"application_name"}
                                                 disabled={this.state.loading}
                                                 onChange={this.handleInputChange}
                                                 value={this.state.application_name || ""}/>
                                </FormGroup>

                                <FormGroup>
                                    <ControlLabel>Description</ControlLabel>
                                    <FormControl componentClass="textarea"
                                                 type="text"
                                                 rows={4}
                                                 name="application_description"
                                                 disabled={this.state.loading}
                                                 onChange={this.handleInputChange}
                                                 placeholder="Enter the description"
                                                 value={this.state.application_description || ""}
                                    />
                                </FormGroup>


                                <FormGroup>
                                    <ControlLabel>SSO URL</ControlLabel>
                                    <InputGroup>
                                        <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                            <Glyphicon glyph="globe"/>
                                        </InputGroup.Addon>
                                        <FormControl
                                            placeholder="Enter external sso url"
                                            type="text"
                                            name="application_sso_url"
                                            disabled={this.state.loading}
                                            onChange={this.handleInputChange}
                                            value={this.state.application_sso_url || ""}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <ControlLabel>Tenant ID (Azure B2C)</ControlLabel>
                                    <InputGroup>
                                        <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                            <Glyphicon glyph="globe"/>
                                        </InputGroup.Addon>
                                        <FormControl
                                            placeholder="Enter tenant id"
                                            type="text"
                                            name="application_tenant_id"
                                            disabled={this.state.loading}
                                            onChange={this.handleInputChange}
                                            value={this.state.application_tenant_id || ""}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <ControlLabel>Tenant Name (Azure B2C)</ControlLabel>
                                    <InputGroup>
                                        <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                            <Glyphicon glyph="globe"/>
                                        </InputGroup.Addon>
                                        <FormControl
                                            placeholder="Enter tenant name"
                                            type="text"
                                            name="application_tenant_name"
                                            disabled={this.state.loading}
                                            onChange={this.handleInputChange}
                                            value={this.state.application_tenant_name || ""}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <ControlLabel>Application Domain</ControlLabel>
                                    <InputGroup>
                                        <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                            <Glyphicon glyph="globe"/>
                                        </InputGroup.Addon>
                                        <FormControl
                                            placeholder="Enter application domain"
                                            type="text"
                                            name="application_domain"
                                            disabled={this.state.loading}
                                            onChange={this.handleInputChange}
                                            value={this.state.application_domain || ""}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: 'thin',
                                        padding: '10px',
                                        borderColor: '#377cb3'
                                    }}>

                                    <ControlLabel>Application Image</ControlLabel>
                                    <ImageUpload
                                        imageName={this.state.application_image}
                                        imageType="applications"
                                        imagePosition={"application_image"}
                                        imageTemp={this.state.application_image_temp}
                                        imageFolderTemp={this.state.application_image_folder_temp}
                                        handlerFromParent={this.handleApplicationImageSelect}
                                    />
                                </FormGroup>

                            </Col>

                            <Col xs={12} sm={12} md={12} lg={6}>
                                {this.renderDeviceLimits()}
                                {this.renderContentProviderSelection()}
                            </Col>
                        </Row>

                    </Grid>
                }
                ftTextCenter
                legend={
                    <div className="buttons-with-margin">
                        <Button bsStyle="info" fill wd
                                name={"saveButton"}
                                disabled={this.state.loading}
                                onClick={() => this.setState({showSaveModal: true})}
                        >
                            Save
                        </Button>
                        <Button bsStyle="default" fill wd
                                name={"cancelButton"}
                                disabled={this.state.loading}
                                onClick={this.cancelApplicationEdit.bind(this)}>
                            {this.state.back_button_text}
                        </Button>
                    </div>
                }
            />

        );
    }

    renderNavigation = () => {

        return (
            <Card content={
                <div className="nav-container">
                    <Nav bsStyle="tabs" bsClass="nav nav-icons">
                        <NavItem eventKey="customer_tab_general">
                            <i className="fa fa-user-circle"/><br/>{this.props.t('customer_edit.customer_tab_general', {framework: "react-i18next"})}
                        </NavItem>
                        <NavItem eventKey="customer_tab_categories">
                            <i className="fa fa-desktop"/><br/>Categories
                        </NavItem>
                        <NavItem eventKey="customer_tab_live_packages">
                            <i className="fa fa-desktop"/><br/>Live Packages
                        </NavItem>
                        <NavItem eventKey="customer_tab_event_packages">
                            <i className="fa fa-dropbox"/><br/>Event Packages
                        </NavItem>
                        <NavItem eventKey="customer_tab_svod_packages">
                            <i className="fa fa-film"/><br/>SVOD Packages
                        </NavItem>
                        <NavItem eventKey="customer_tab_sport_packages">
                            <i className="fa fa-futbol-o"/><br/>Sport Packages
                        </NavItem>
                        <NavItem eventKey="customer_tab_device_ordering">
                            <i className="fa fa-clock-o"/><br/>Device Ordering
                        </NavItem>
                        <NavItem eventKey="customer_tab_device_menu">
                            <i className="fa fa-credit-card-alt"/><br/>Device Menu
                        </NavItem>
                        <NavItem eventKey="customer_tab_device_apk">
                            <i className="fa fa-envelope-o"/><br/>APK
                        </NavItem>
                    </Nav>
                </div>
            }
            />
        );

    }

    renderApk = () => {
        return (
            <DeviceApkManagementComponent
                applicationId={this.state.application_id}
            />
        );
    }

    renderDeviceOrdering = () => {
        return (
            <DeviceOrderingComponent
                applicationId={this.state.application_id}
            />
        );
    }

    renderDeviceMenu = () => {
        return (
            <DeviceMenuComponent
                applicationId={this.state.application_id}
            />
        );
    }

    render() {
        return (

            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Tab.Container id="nav-with-icons" defaultActiveKey="customer_tab_general">
                    <div>
                        {this.renderNavigation()}

                        <Tab.Content>
                            <Tab.Pane eventKey="customer_tab_general">
                                {this.renderMainTab()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_categories">
                                {this.renderCategories()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_live_packages">
                                {this.renderLiveSelection()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_event_packages">
                                {this.renderEventSelection()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_svod_packages">
                                {this.renderSvodSelection()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_sport_packages">
                                {this.renderSportPackageSelection()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_device_ordering">
                                {this.renderDeviceOrdering()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_device_menu">
                                {this.renderDeviceMenu()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_device_apk">
                                {this.renderApk()}
                            </Tab.Pane>

                        </Tab.Content>
                    </div>
                </Tab.Container>


            </div>

        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedApplicationManagementEdit = connect(mapStateToProps)(ApplicationManagementEdit);
const connectedApplicationManagementEditTrans = withNamespaces('common')(connectedApplicationManagementEdit);
export {connectedApplicationManagementEditTrans as ApplicationManagementEdit};
